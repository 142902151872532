import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";


import { ProjectList } from "./components/ProjectList";
import { NewProject } from "./components/NewProject";

ReactDOM.render(<div>
        <Router>
            <Route path="/" component={ProjectList}></Route>
            <Route path="/create-new-project" component={NewProject}></Route>
        </Router>
    </div>
    ,
    document.getElementById("main")
);
