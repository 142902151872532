import * as React from 'react';
import { Link } from 'react-router-dom';

interface Project {
    name: string
}

interface ProjectListState {
    projects: Project[]
}

export class ProjectList extends React.Component<{}, ProjectListState> {

    constructor(props: {}) {
        super(props)
        this.state = { projects: [] }
    }

    componentDidMount() {
        fetch('/api/projects')
            .then(response => response.json())
            .then(responseJson => this.setState({projects: responseJson}))
    }

    render() {
        return (<div id="projectList">
            <h1>Projects</h1>
            <Link to="/create-new-project">Create New Project</Link>
            <div id="projectListItems">
                <ul>
                    {this.state.projects.map(project => (<li>{project}</li>))}
                </ul>
            </div>
        </div>);
    }

}