import * as React from 'react';
import { ErrorResponse } from '../responses';

interface NewProjectState {
    projectName: string
    errorMessage: string
}

export class NewProject extends React.Component<{}, NewProjectState> {

    constructor(props: {}) {
        super(props)
        this.state = { projectName: "", errorMessage: "" }
    }

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null) {
            this.setState({ projectName: event.target.value })
        }
    }

    createProject = () => {
        fetch('/api/projects', {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: this.state.projectName})
        }).then(response => {
            if (response.status == 200) {
                // Redirect
            } else {
                response.json()
                    .then((responseJson: ErrorResponse) => this.setState({ errorMessage: responseJson.errorMessage }))
            }
        }).catch(error => this.setState({ errorMessage: 'Request failed. Please try again.' }))
    }

    render() {
        return (<div id="new-project">
            <span id="project-name-input-error">{this.state.errorMessage}</span>
            <input id="project-name-input" type="text" onChange={this.handleNameChange} value={this.state.projectName}/>
            <button onClick={this.createProject}>Create</button>
        </div>);
    }

}